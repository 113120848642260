[
  {
    "names": [
      "tailwind.css"
    ],
    "generatedName": "tailwind.6a29d174da3b6b90b7bd9584f6298904.css"
  },
  {
    "names": [
      "pureleap-web--__index.map"
    ],
    "generatedName": "-__index.1fca20841dbf824f911e982a07e0c6df.map.json"
  },
  {
    "names": [
      "pureleap-web--__index-bundle.js"
    ],
    "generatedName": "-__index-bundle.48154cb6bd27b7762c6defa810a568b5.js"
  },
  {
    "names": [
      "pureleap-web-posts.map"
    ],
    "generatedName": "posts.7c154568e9a7799e17a0236417467056.map.json"
  },
  {
    "names": [
      "pureleap-web-posts-bundle.js"
    ],
    "generatedName": "posts-bundle.361b8c5b69071a728cedca9a551fea85.js"
  },
  {
    "names": [
      "pureleap-web-about.map"
    ],
    "generatedName": "about.88810fc67160dbfdf0c95a6984648e94.map.json"
  },
  {
    "names": [
      "pureleap-web-about-bundle.js"
    ],
    "generatedName": "about-bundle.b2506cb9bc2774c7411b3f477f6f7437.js"
  },
  {
    "names": [
      "pureleap-web-blog.map"
    ],
    "generatedName": "blog.f249d6b8b4a27bab08898ab838c406a0.map.json"
  },
  {
    "names": [
      "pureleap-web-blog-bundle.js"
    ],
    "generatedName": "blog-bundle.96ef153bec047052bcb0e1ae3c3a7a13.js"
  },
  {
    "names": [
      "pureleap-web-categories-_id_.map"
    ],
    "generatedName": "categories-_id_.04259e6015e0119a39e8d45336f46fe6.map.json"
  },
  {
    "names": [
      "pureleap-web-categories-_id_-bundle.js"
    ],
    "generatedName": "categories-_id_-bundle.f2d050510671113de077cca8b0035974.js"
  },
  {
    "names": [
      "pureleap-web-robots_txt.map",
      "pureleap-web-prod-robots_txt.map"
    ],
    "generatedName": "robots_txt.1f44ff6861686befb5ea24c52dd17289.map.json"
  },
  {
    "names": [
      "pureleap-web-robots_txt-bundle.js",
      "pureleap-web-prod-robots_txt-bundle.js"
    ],
    "generatedName": "robots_txt-bundle.623cff4c7fe9d0bf8f70c44f60cb1aeb.js"
  },
  {
    "names": [
      "pureleap-web-sitemap_xml.map",
      "pureleap-web-prod-sitemap_xml.map"
    ],
    "generatedName": "sitemap_xml.c8bab6179b7f9ab5030465a564dfda9f.map.json"
  },
  {
    "names": [
      "pureleap-web-sitemap_xml-bundle.js",
      "pureleap-web-prod-sitemap_xml-bundle.js"
    ],
    "generatedName": "sitemap_xml-bundle.098115e6feb6b513140080074e6dddfd.js"
  },
  {
    "names": [
      "pureleap-web-tags-_id_.map"
    ],
    "generatedName": "tags-_id_.5ba46558471bc439e24c2b4aba7a475e.map.json"
  },
  {
    "names": [
      "pureleap-web-tags-_id_-bundle.js"
    ],
    "generatedName": "tags-_id_-bundle.db7cd20fe4c70ab42de8510486aa8bb6.js"
  },
  {
    "names": [
      "pureleap-web-_post__.map"
    ],
    "generatedName": "_post__.d4762b408b7aebb369479469399878b9.map.json"
  },
  {
    "names": [
      "pureleap-web-_post__-bundle.js"
    ],
    "generatedName": "_post__-bundle.78b550b7c6c7010759e734fee54650aa.js"
  },
  {
    "names": [
      "pureleap-web-prod--__index.map"
    ],
    "generatedName": "-__index.94584e5de723ca09a8e57ffa0cb7832c.map.json"
  },
  {
    "names": [
      "pureleap-web-prod--__index-bundle.js"
    ],
    "generatedName": "-__index-bundle.8cf8f7a78f6f50312b3239acb15b88db.js"
  },
  {
    "names": [
      "pureleap-web-prod-about.map"
    ],
    "generatedName": "about.ae6150ace55c3777cd2964e75a892fb7.map.json"
  },
  {
    "names": [
      "pureleap-web-prod-about-bundle.js"
    ],
    "generatedName": "about-bundle.2bb4654f4683a7aef22452e1c4ff7ecd.js"
  },
  {
    "names": [
      "pureleap-web-prod-blog.map"
    ],
    "generatedName": "blog.f47bbdb4b3996c0b9b806e643f35d58c.map.json"
  },
  {
    "names": [
      "pureleap-web-prod-blog-bundle.js"
    ],
    "generatedName": "blog-bundle.f555e97b0d8f2a53f4f011bc1593ea20.js"
  },
  {
    "names": [
      "pureleap-web-prod-categories-_id_.map"
    ],
    "generatedName": "categories-_id_.e27657e552f04d884bd0da3bb5da2674.map.json"
  },
  {
    "names": [
      "pureleap-web-prod-categories-_id_-bundle.js"
    ],
    "generatedName": "categories-_id_-bundle.8662db602a469365964a42f246fb5e77.js"
  },
  {
    "names": [
      "pureleap-web-prod-tags-_id_.map"
    ],
    "generatedName": "tags-_id_.6db141066ec173b69c2cd7974959b76a.map.json"
  },
  {
    "names": [
      "pureleap-web-prod-tags-_id_-bundle.js"
    ],
    "generatedName": "tags-_id_-bundle.d2e05f053617e82cd1a7f8fb2f066a29.js"
  },
  {
    "names": [
      "pureleap-web-prod-_post__.map"
    ],
    "generatedName": "_post__.d3353aef8c47691f665733735fd9431c.map.json"
  },
  {
    "names": [
      "pureleap-web-prod-_post__-bundle.js"
    ],
    "generatedName": "_post__-bundle.70113f846c29263fa4418be59fd53b33.js"
  },
  {
    "names": [
      "pureleap-web-prod-objecthub.map",
      "pureleap-web-objecthub.map"
    ],
    "generatedName": "objecthub.3db0ed1974606d0275550677fcf16fe2.map.json"
  },
  {
    "names": [
      "pureleap-web-prod-objecthub-bundle.js",
      "pureleap-web-objecthub-bundle.js"
    ],
    "generatedName": "objecthub-bundle.1a837f97d9f64ee9630bf50ec427a963.js"
  },
  {
    "names": [
      "pureleap-web-prod-recipes.map"
    ],
    "generatedName": "recipes.ed953548b7b1755cdc9c68486f39de3c.map.json"
  },
  {
    "names": [
      "pureleap-web-prod-recipes-bundle.js"
    ],
    "generatedName": "recipes-bundle.ec9a8d37c9043f04fe1345f949d839dc.js"
  },
  {
    "names": [
      "pureleap-web-recipes.map"
    ],
    "generatedName": "recipes.000b5a8862cbc5541565557e11af7950.map.json"
  },
  {
    "names": [
      "pureleap-web-recipes-bundle.js"
    ],
    "generatedName": "recipes-bundle.213af69e12174e04debbbaa9e9708f71.js"
  }
]